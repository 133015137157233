import { graphql, PageProps } from 'gatsby'

import Layout from '../layouts'
import BlogSection from '../sections/BlogSection'

const BlogHome = ({ data }: PageProps<Queries.AllArticlesQuery>) => {
  return (
    <Layout
      smallNavBar
      seo={{
        title: data.strapiBlogPage?.seo?.title,
        description: data.strapiBlogPage?.seo?.description
      }}
    >
      <BlogSection data={data} />
    </Layout>
  )
}

export default BlogHome

export const query = graphql`
  query AllArticles {
    allStrapiArticle {
      edges {
        node {
          id
          title
          shortDescription
          slug
          updatedAt
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 400, formats: [AUTO, WEBP])
              }
            }
            alternativeText
          }
        }
      }
    }
    strapiBlogPage {
      blogDescription {
        data {
          blogDescription
        }
      }
      seo {
        description
        title
      }
    }
  }
`
