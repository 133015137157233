import { Link, PageProps } from 'gatsby'
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'
import styled from 'styled-components'

import { FlexCard, Intro, TitleLarge } from '../../common/styles'
import MarkdownWrapper from '../../components/MarkdownWrapper'
import border from '../../constants/border'
import breakpoint from '../../constants/breakpoint'
import spacing from '../../constants/spacing'

import { Container } from './BlogSection.styles'

const Illustration = styled(GatsbyImage)`
  width: 200px;
  border-radius: ${border.LARGE_RADIUS};
  object-fit: cover;
  object-position: center center;
  margin-bottom: ${spacing.MEDIUM_PADDING};

  @media ${breakpoint.TABLET} {
    width: 400px;
  }
`

const Title = styled(TitleLarge)`
  margin-top: 28px;
  padding-right: 7px;
  padding-left: 7px;
`

const Block = styled.div`
  &:not(:last-child) {
    padding-bottom: 21px;
    border-bottom: dotted 1px black;
    margin-bottom: 35px;
  }
`

const Header = styled.h2`
  font-family: DM Serif Display;
  font-size: 20px;
  margin-bottom: 14px;
  line-height: 28px;
  font-weight: 400;
`

const Text = styled.p`
  line-height: 28px;
  margin-bottom: 14px;
`

const UpdatedAt = styled.p`
  font-size: 12px;
  margin-bottom: 14px;
  font-style: italic;
`

const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: start;
  padding: 35px 21px;
  max-width: 100%;
`

const Card = styled(FlexCard)`
  width: 500px;
  padding: ${spacing.MAIN_PADDING};
`

const BlogSection = ({
  data: {
    allStrapiArticle: { edges },
    strapiBlogPage
  }
}: Pick<PageProps<Queries.AllArticlesQuery>, 'data'>) => (
  <Container>
    <Title>
      Blog: astuces et conseil maquillage, par une maquilleuse professionnelle
    </Title>
    <Intro>
      <MarkdownWrapper>
        {strapiBlogPage!.blogDescription.data?.blogDescription!}
      </MarkdownWrapper>
    </Intro>
    <CardsContainer>
      {[...edges]
        .sort((a, b) => Number(a.node.updatedAt) - Number(b.node.updatedAt))
        .map(
          (
            { node: { shortDescription, slug, title, id, image, updatedAt } },
            index
          ) => {
            const imageData = getImage(
              image?.localFile as unknown as IGatsbyImageData
            )

            return (
              <Card key={index}>
                <Block key={id}>
                  {imageData && (
                    <Illustration
                      image={imageData}
                      alt={image?.alternativeText!}
                    />
                  )}
                  <Header>{title}</Header>
                  <UpdatedAt>
                    Mis à jour le{' '}
                    {new Date(updatedAt!).toLocaleDateString('fr-FR', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric'
                    })}
                  </UpdatedAt>
                  <Text>{shortDescription}</Text>
                  <Link to={`/blog/${slug}/`}>Voir l'article</Link>
                </Block>
              </Card>
            )
          }
        )}
    </CardsContainer>
  </Container>
)

export default BlogSection
